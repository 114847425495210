import './App.css';
import WeinrichHome from './WeinrichHome.jsx';

function App() {
  return (
    <div className="App">
      <WeinrichHome />
    </div>
  );
}

export default App;
