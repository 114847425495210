import React, {useState, useEffect} from 'react';
import './WeinrichHome.css';
import piperNormal from './media/piper-not-pet.png';
import piperPet from './media/piper-pet.png';
import heart from './media/heart.png';
import stravaBot from './media/strava-bot.PNG';
import iconRun from './media/activity-icons/run.svg';
import iconRide from './media/activity-icons/bike.svg';
import iconGolf from './media/activity-icons/golf.svg';
import iconPaddle from './media/activity-icons/kayak.svg';
import iconActivity from './media/activity-icons/workout.svg';
import iconGithub from './media/github.svg';
import { DateTime } from 'luxon';
import * as THREE from 'three';


function WeinrichHome() {



	const [miles, setMiles] = useState('0')
	const [spotify, setSpotify] = useState([])
	const [strava, setStrava] = useState([])
	const [spotifyPeriod, setSpotifyPeriod] = useState(0)
	const periods = ['the past month', 'the past 6 months', 'the past couple years']

	const love = []
	const loveAmplitude = []
	const loveDirection = []
	const loveRotation = []
	const startX = 0
	const startY = 1
	const startZ = -0
	let renderCount = 0

	let group



	let camera, scene, renderer, raycaster
	const textureLoader = new THREE.TextureLoader();
	
	const texture = textureLoader.load( 'textures/uv_grid_opengl.jpg' );
	texture.colorSpace = THREE.SRGBColorSpace;
	// it's necessary to apply these settings in order to correctly display the texture on a shape geometry
	texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
	texture.repeat.set( 0.008, 0.008 );

	renderer = new THREE.WebGLRenderer({ alpha: true });
	renderer.setClearColor(0xffffff, 0);
    renderer.setSize( window.innerWidth, window.innerHeight );

	const pointer = new THREE.Vector2();
	const mouse = new THREE.Vector2();
	const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );

	function initScene() {
		camera = new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1, 1000 )
        camera.position.y = 2
        camera.position.z = 10
        
        scene = new THREE.Scene()


        const light = new THREE.PointLight( 0xffffff, 0.8 );
        light.position.set(0, 150, 500)
		scene.add( light );

        //const axesHelper = new THREE.AxesHelper( 50 );
    	//scene.add( axesHelper );

        raycaster = new THREE.Raycaster();

        window.addEventListener( 'resize', onResize, false );
        document.addEventListener( 'mousemove', onMouseMove );

        const piper = document.getElementById('piper');
       	piper.addEventListener('click', lovePiper);

	}

	function onMouseMove( ev ) {

        mouse.x = ( ev.clientX );
        mouse.y = ( ev.clientY );

        pointer.x = ( ev.clientX / window.innerWidth ) * 2 - 1;
        pointer.y = - ( ev.clientY / window.innerHeight ) * 2 + 1;

    }

    
    let xFactor = 0.55
    if(window.innerWidth > 400) {
    	xFactor = 1.6
    }

    function onResize( ev ) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        windowHalf.set( width / 2, height / 2 );
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize( width, height );

        xFactor = 0.55
        if(window.innerWidth > 400) {
	    	xFactor = 1.6
	    }

    }


    const x = 0, y = 0;

	const heartShape = new THREE.Shape()
		.moveTo( x + 25, y + 25 )
		.bezierCurveTo( x + 25, y + 25, x + 20, y, x, y )
		.bezierCurveTo( x - 30, y, x - 30, y + 35, x - 30, y + 35 )
		.bezierCurveTo( x - 30, y + 55, x - 10, y + 77, x + 25, y + 95 )
		.bezierCurveTo( x + 60, y + 77, x + 80, y + 55, x + 80, y + 35 )
		.bezierCurveTo( x + 80, y + 35, x + 80, y, x + 50, y )
		.bezierCurveTo( x + 35, y, x + 25, y + 25, x + 25, y + 25 );

	const extrudeSettings = { depth: 8, bevelEnabled: true, bevelSegments: 4, steps: 4, bevelSize: 2, bevelThickness: 2 };
	const heartScale = 0.0125

    function lovePiper() {

    	const zrand = (Math.random()*6)-3

    	const geometry = new THREE.ExtrudeGeometry( heartShape, extrudeSettings );

		const mesh = new THREE.Mesh( geometry, new THREE.MeshPhongMaterial( { color: 0xf00000 } ) );
		mesh.position.set( startX, startY, zrand);
		mesh.rotation.set( 0, 0, Math.PI);
		mesh.scale.set( (xFactor)*heartScale, heartScale, heartScale );
		scene.add( mesh );

        const len = love.length
        love[len] = mesh
        loveDirection[len] = (Math.random()*360)*(Math.PI/180)
        loveAmplitude[len] = Math.random()*0.02
        loveRotation[len] = (Math.random()*0.04)-0.02

        scene.add(love[len])

        console.log(len)

        setTimeout(() => {
        	scene.remove(love[len])
        	love[len] = ''
        }, 3500)


    }

    function render() {
        camera.lookAt(0,1,0)


        for(let i = 0; i < love.length; i++) {
        	if(love[i] !== '') {
        		love[i].position.y += 0.05
        		love[i].position.x += loveAmplitude[i]*(Math.sin(renderCount/2))

        		love[i].rotation.y += loveRotation[i]

        		
        	}
        }

        renderCount += 1/60





        renderer.render( scene, camera );
    }


    function animate() {
        requestAnimationFrame( animate );
        render()
    }

    

    function runScene() {
        initScene()
        animate()
        const sceneHold = document.getElementById('scene')
        sceneHold.appendChild(renderer.domElement)
    }




	useEffect(() => {
		fetch('https://weinrich.xyz/api/get-ytd-mileage.php')
		.then((response) => response.json())
		.then((data) => {
			setMiles(data[0].miles)
		})

		fetch('https://weinrich.xyz/api/retrieve-songs.php')
		.then((response) => response.json())
		.then((data) => {
			setSpotify(data)
		})

		fetch('https://weinrich.xyz/api/retrieve-activities.php')
		.then((response) => response.json())
		.then((data) => {
			setStrava(data)
		})

	}, [])

	useEffect(() => {

		runScene()

	})


		


	function PetPiper() {


		function petPiper() {
			const piper = document.getElementById('piper')
			piper.src = piperPet	
			piper.disabled = true
			setTimeout(() => {
				piper.src = piperNormal
				piper.disabled = false
			}, 2000)
		}

		

		return (
			<div className="d-flex flex-row mb-5">
				<div className="d-flex flex-column">
					<h2 className="text-start song-heading mb-0 px-4 weinrich-sans">This is Piper</h2>
					<span className="sub-text px-4">she would love a head scratch</span>
					<div className="px-5 mt-4 position-relative">
						<img src={piperNormal} className="piper" id="piper" onClick={() => petPiper()} />
						<div id="scene" className="piper-scene"></div>
					</div>
					
				</div>
			</div>
		)

	}


	function Activities() {

		const activitiesContent = []

		for(let i = 0; i < strava.length; i++) {

			const distMiles = (strava[i].distance/1609.34).toFixed(2)
			const activityDateTime = DateTime.fromFormat(strava[i].date, 'yyyy-MM-dd HH:mm:ss')

			const formattedDate = activityDateTime.toLocaleString({
			   month: 'numeric',
			  day: 'numeric',
			})

			const formattedTime = activityDateTime.toLocaleString({
			  hour: 'numeric',
			  minute: '2-digit',
			})


			const seconds = strava[i].time
		      const secondsTot = strava[i].time_total
		      let duration = 0
		      if(seconds > 3600) {
		        const hours = Math.trunc(seconds/3600)
		        const minutes = ((seconds%3600)/60).toFixed(0)
		        duration = hours+' hr '+minutes+' min'
		      } else {
		        const minutes =(seconds/60).toFixed(0)
		        duration = minutes+' min'
		      }
		      let durationTot = 0
		      if(secondsTot > 3600) {
		        const hoursTot = Math.trunc(secondsTot/3600)
		        const minutesTot = ((secondsTot%3600)/60).toFixed(0)
		        durationTot = hoursTot+' hr '+minutesTot+' min'
		      } else {
		        const minutesTot = (seconds/60).toFixed(0)
		        durationTot = minutesTot+' min'
		      }
		      const speed = (distMiles/(seconds/3600)).toFixed(1)
	        const paceRaw = (seconds/distMiles)
	        const paceMin = Math.trunc(paceRaw/60)
	        let paceSec = (((paceRaw/60)%paceMin)*60).toFixed(0)
	        if(paceSec < 10) {
	          paceSec = paceSec.toString().padStart(2, '0')
	        }
	        const pace = paceMin+':'+paceSec

	        let activityString = ''
	        let speedString = ''
	        let activityIcon = iconActivity

			if(strava[i].type === 'Run') {
				activityString = distMiles + ' mile run'
				speedString = ' | '+duration+' | '+pace+' per mile'
				activityIcon = iconRun

			} else if(strava[i].type === 'Ride') {
				activityString = distMiles + ' mile ride'
				speedString = ' | '+duration+' | '+speed+' mph avg'
				activityIcon = iconRide

			} else if(strava[i].type === 'Golf') {
				activityString = durationTot+' '+strava[i].type.toLowerCase()+' session'
				speedString = ' '
				activityIcon = iconGolf

			} else if(strava[i].type === 'Kayak') {
				activityString = durationTot+' '+strava[i].type.toLowerCase()+' session'
				speedString = ' '
				activityIcon = iconPaddle

			} else {
				activityString = durationTot+' '+strava[i].type.toLowerCase()+' session'
				speedString = ' '
				activityIcon = iconActivity
			}
			
			activitiesContent.push(
				<div key={i} className="d-flex flex-row align-items-center mb-4 px-3">
					<div className="d-flex flex-column mx-3">
						<img className="activity-icon" src={activityIcon} />
					</div>
					<div className="d-flex flex-column px-1 text-start">
						<h4 className="activityName my-1">{strava[i].title}</h4>
						<p className="activity-details my-0">{activityString} {speedString}</p>
						<p className="activity-details my-0">{formattedDate} | {formattedTime}</p>
						
					</div>
				</div>
			)
		}
		
		
		

		return (
			<div className="d-flex flex-row">
				<div className="d-flex flex-column">
					<h2 className="text-start song-heading mb-0 px-4">Here are some recent activites</h2>
					<p className="text-start sub-text mb-4 px-4 smaller-font">With Strava's v3 API, I can fetch my recent activity data via GET requests to the <em>/athlete/activities</em> endpoint</p>
					{activitiesContent}
					<img src={stravaBot} className="example-img mt-3 mb-2 mx-auto" />
					<p className="text-start sub-text mb-2 px-4 mx-1 smaller-font">I also made a bot, hosted on Heroku and served through GitHub, which listens for new activities from members of a specified Strava club, and then leverages the Discord (or Slack) API to send dynamic embedded messages to a designated channel</p>
					<span className="px-4 mb-5 mx-1">
						<a href="https://github.com/asweinrich/strava-bot" target="_blank" className="github-link weinrich-sans bigger-font">
							<img src={iconGithub} className="icon-github me-2" />
							View on GitHub
						</a>
					</span>
				</div>
			</div>
		)
	}


	function TopSongs() {

		const topSongsContent = []
		let min = 0 
		let max = 10

		if(spotifyPeriod === 1) {
			min = 10
			max = 20

		} else if(spotifyPeriod === 2) {
			min = 20
			max = 30

		} else {
			min = 0
			max = 10
		}

		if(spotify.length > 0) {
			for(let i = min; i < max; i++) {
				let albumName = spotify[i].album
				if (albumName.length > 26) {
				    albumName = albumName.substring(0, 26) + "...";
				  }
				topSongsContent.push(
					<a key={i} href={spotify[i].url} target="_blank" className="spotify-url">
					<div className="d-flex flex-row align-items-center mb-4 ps-3">
						
						<div className="d-flex flex-column song-rank text-center me-3">
							{spotify[i].id - min}
						</div>
						<div className="d-flex flex-column me-3">
							<img src={spotify[i].image} className="song-img" />
						</div>
						<div className="d-flex flex-column px-1 text-start">
							<h4 className="song-name my-0">{spotify[i].name}</h4>
							<p className="song-artist pt-1 mb-0">{spotify[i].artist}</p>
							<p className="song-album my-0">{albumName}</p>
						</div>
						
					</div>
					</a>
				)
			}
		}

		
		

		return (
			<div className="d-flex flex-row mb-5">
				<div className="d-flex flex-column">
					<h2 className="text-start song-heading mb-0 px-4">Here are my favorite songs from</h2>
					<div className="px-2">
						<div className="dropdown mb-1">
						  <button className="btn btn-secondary dropdown-toggle px-3 w-100 text-start weinrich-sans bigger-font" id="current-spotify-timefram" type="button" data-bs-toggle="dropdown" aria-expanded="false">
						    {periods[spotifyPeriod]}
						  </button>
						  <ul className="dropdown-menu weinrich-sans bigger-font">
						    <li><span className="dropdown-item" onClick={() => {setSpotifyPeriod(0)}}>the past month</span></li>
						    <li><span className="dropdown-item" onClick={() => {setSpotifyPeriod(1)}}>the past 6 months</span></li>
						    <li><span className="dropdown-item" onClick={() => {setSpotifyPeriod(2)}}>the past couple years</span></li>
						  </ul>
						</div>
						<p className="text-start sub-text smaller-font mb-4 px-3">I recently started playing around with Spotify's API and figured out how to see my most played songs over different time ranges, so here they are.<br/><br/>Tap a song to listen on Spotify</p>
					</div>
					{topSongsContent}
				</div>
			</div>
		)
	}



	return (
		<div className="d-flex flex-column text-start">
			<h1 className="site-title p-3 weinrich-sans">WEINRICH.XYZ</h1>
			

			<PetPiper />

			

			<TopSongs />

			<Activities />

		</div>

	)
}

export default WeinrichHome;